import axios from "axios";
import { AuthActionType } from "redux/actions/auth.action";
import { UserActionTypes } from "redux/actions/users.action";

const userState = {
  current: {},
  list: [],
};

const userReducer = (state = userState, action) => {
  switch (action.type) {
    case UserActionTypes.GET_USER: {
      return { list: [...action.payload] };
    }

    case AuthActionType.LOGIN_SUCCESS: {
      return userState;
    }
    default:
      return state;
  }
};

export default userReducer;
