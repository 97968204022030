/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Fade from "@mui/material/Fade";
import BackDrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Custom styles for the SuiModal
import styles from "components/SuiModal/styles";
import SuiTypography from "components/SuiTypography";

function SuiModal({
  color,
  children,
  open,
  header,
  closeModal,
  ignoreBackdropClick,
  rightSection = null,
  ...rest
}) {
  const [alertStatus, setAlertStatus] = useState("mount");
  const classes = styles({ color });

  const handleAlertStatus = () => setAlertStatus("fadeOut");

  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={open} timeout={300}>
      <BackDrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={ignoreBackdropClick ? () => {} : closeModal}
      >
        <SuiBox {...rest} customclass={classes.alert}>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center">
            <SuiBox display="flex" flex={1} justifyContent="space-between">
              <SuiTypography fontWeight="medium" textColor="white">{header}</SuiTypography>
              {rightSection && (
                <SuiBox display="flex" alignItems="center">
                  {rightSection}
                </SuiBox>
              )}
            </SuiBox>
            <CloseIcon color="light" fontSize="large" cursor="pointer" onClick={closeModal} />
          </SuiBox>

          <SuiBox
            display="flex"
            sx={{
              paddingTop: "1rem",
              maxHeight: "40rem",
              overflow: "auto",
            }}
          >
            {children}
          </SuiBox>
        </SuiBox>
      </BackDrop>
    </Fade>
  );

  // switch (open) {
  //   case true:
  //     return setAlertStatus("mount");
  //   case false:
  //     return setAlertStatus("fadeOut");
  //   default:
  //     alertTemplate();
  //     break;
  // }

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

// Setting default values for the props of SuiModal
SuiModal.defaultProps = {
  color: "info",
  dismissible: false,
  open: false,
  ignoreBackdropClick: true,
};

// Typechecking props of the SuiModal
SuiModal.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  dismissible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  ignoreBackdropClick: PropTypes.bool,
};

export default SuiModal;
