import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./fileUpload.module.scss";

// import Text from "components/SuiTypography";
// import UploadMedia from "redux/services/uploadMedia.service";
// import { NotifierInstance } from "utils/notification/notify";
import SuiBox from "components/SuiBox";
import { Label } from "reactstrap";
import axios from "axios";

function FileUpload(props) {
  const { label, url, onChange, name, accept, disabled = false } = props;

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [type, setType] = useState("img");

  const handleOnUploadProgress = (percentage) => setProgress(percentage);

  const getUploadUrl = (filename) => {
    return axios.get(`file/generateUploadUrl/${filename}`);
  };

  const uploadImage = (file, fileUrl, type) => {
    let token = axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Authorization"];
    axios({
      url: fileUrl,
      method: "PUT",
      headers: {
        "Content-Type": type,
      },
      data: file,
      onUploadProgress: (progressEvent) => {
        let percentage = (progressEvent.loaded / file.size) * 100;
        handleOnUploadProgress(percentage);
      },
    })
      .then(function (resp) {
        if (resp.status === 200) {
          let imageUrl = fileUrl.split("?")[0];
          onChange({ [name]: imageUrl });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        axios.defaults.headers.common["Authorization"] = token;
        setUploading(false);
      });
  };

  const handleOnChange = async (e) => {
    setUploading(true);
    const files = e.target.files;
    const type = files[0].type;
    let response = await getUploadUrl(files[0].name);
    let fileUrl = response?.data?.url || "";
    uploadImage(files[0], fileUrl, type, e);
  };

  const renderPreUploadContent = () => {
    if (uploading)
      return (
        <div className={styles.file_upload_progress_bar} data-progress={progress + "%"}>
          <div style={{ width: `${progress}%` }}></div>
        </div>
      );
    return (
      <React.Fragment>
        <input type="file" onChange={handleOnChange} disabled={disabled} accept={accept} />
        <div className={styles.file_select}>
          <Label variant="body2">Select File</Label>
          <Label variant="body2">OR</Label>
          <Label variant="body2">Drop Here</Label>
        </div>
      </React.Fragment>
    );
  };

  const render = () => {
    if (url)
      return (
        <React.Fragment>
          <img src={url} alt="image" style={{ width: "260px", height: "200px" }} />
          {!disabled && (
            <div
              title="Remove File"
              onClick={() => onChange({ [name]: null })}
              className={styles.file_upload_remove_file}
            >
              <span className="icon-close" style={{ fontSize: "12px" }}>
                x
              </span>
            </div>
          )}
        </React.Fragment>
      );
    return renderPreUploadContent();
  };

  useEffect(() => () => {}, []);

  return (
    <div
      className={styles.file_upload_widget_wrapper}
      style={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled && "no-drop",
      }}
    >
      {label && (
        <SuiBox>
          <Label variant="overline" style={{ color: "#fff", fontSize: "14px", fontWeight: "400" }}>
            {label}
          </Label>
        </SuiBox>
      )}

      <div className={styles.file_upload_wrapper}> {render()}</div>
    </div>
  );
}

FileUpload.propTypes = {};

export default React.memo(FileUpload);
