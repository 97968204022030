import React, { useState, Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import classnames from "classnames";

import "./styles.scss";
import Form1 from "components/ShowcaseForms";
import CustomTable from "components/ShowcaseTables";

const ShowcaseTabs = props => {
  const [activeTab, setActiveTab] = useState(1);

  const toggle = tab => (activeTab !== tab ? setActiveTab(tab) : null);
  return (
    <Fragment>
      <div className="custom-tabs">
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === 1 })} onClick={() => toggle(1)}>
              Create New Showcase
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === 2 })} onClick={() => toggle(2)}>
             Showcase List
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          {activeTab==1?(
          <TabPane tabId={1}>
            <Row>
              <Col sm="12">
                <Form1 />
              </Col>
            </Row>
          </TabPane>):null}
          {activeTab==2?(
          <TabPane tabId={2}>
            <Row>
              <Col sm="12">
                <CustomTable />
              </Col>
            </Row>
          </TabPane>
          ):null}
        </TabContent>
      </div>
    </Fragment>
  );
};

export default ShowcaseTabs;
