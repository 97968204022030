import { scheduledContent } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";

export const ContentActionTypes = {
  CREATE_SCHEDULED_CONTENT: "CREATE_SCHEDULED_CONTENT",
  DELETE_SCHEDULED_CONTENT: "DELETE_SCHEDULED_CONTENT",
  UPDATE_SCHEDULED_CONTENT: "UPDATE_SCHEDULED_CONTENT",
  GET_SCHEDULED_CONTENT: "GET_SCHEDULED_CONTENT",
};

export const getContent = () => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id } = current;
    return axios
      .get(scheduledContent, {
        params: {
          id:_id,
        },
      })
      .then((res) => {
        const { data } = res;
        dispatch({ type: ContentActionTypes.GET_SCHEDULED_CONTENT, payload: data });
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Scheduled Content Error..").error(_err);
        throw err;
      });
  };
};

export const createContent = (payload) => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id } = current;
    return axios
      .post(scheduledContent, { ...payload, brand_id:_id })
      .then((res) => {
        getContent()(dispatch, getState);
        return res;})
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Scheduled Content Error..").error(_err);
        throw err;
      });
      // .then((res) => getContent()(dispatch, getState))
      // .catch((err) => {
      //   const _err = err?.response?.data?.error_message;
      //   NotifierInstance.setTitle("Scheduled Content Error..").error(_err);
      //   throw err;
      // });
  };
};

export const updateContent = (payload) => {
  return (dispatch, getState) =>
    axios
      .put(`${scheduledContent}/${payload._id}`, payload)
      .then((res) => getContent()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Scheduled Content Error..").error(_err);
        throw err;
      });
};

export const deleteContent = (item) => {
  return (dispatch, getState) =>
    axios
      .delete(`${scheduledContent}/${item._id}`)
      // .then((res) => getContent()(dispatch, getState))
      .then((res) => res)
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Scheduled Content Error..").error(_err);
        throw err;
      });
};

export const deleteManyContent = (ids) => {
  return (dispatch, getState) =>
    axios
      .delete(`${scheduledContent}/delete`,{data:{ids}})
      .then((res) => getContent()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Scheduled Content Error..").error(_err);
        throw err;
      });
};


