import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import authreducer from "./reducers/auth.reducer";
import authErrorReducer from "./reducers/auth.errorreducer";
import contentReducer from "./reducers/content.reducer";
import channelReducer from "./reducers/channel.reducer";
import scheduledReducer from "./reducers/schedule.reducer";
import showcaseReducer from "./reducers/showcase.reducer";
import userReducer from "./reducers/user.reducer";
import uploadReducer from "./reducers/upload.reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  authState: authreducer,
  authError: authErrorReducer,
  content: contentReducer,
  channel: channelReducer,
  users: userReducer,
  schedule:scheduledReducer,
  showcase:showcaseReducer,
  uploadImage:uploadReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
export { store };
