import { login, setPassword, forgetPassword } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";

const AuthActionType = {
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAIL: "REGISTER_FAIL",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAIL: "LOGOUT_FAIL",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  PASSWORD_SET_SUCCESS: "PASSWORD_SET_SUCCESS",
  PASSWORD_SET_FAIL: "PASSWORD_SET_FAIL",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_FAIL: "FORGET_PASSWORD_FAIL",
};

const RegisterAuthAction = (userState, history, setErrorHandler) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/register", userState);
      const { data } = res;
      dispatch({ type: AuthActionType.REGISTER_SUCCESS, payload: data });
      history.push("/");
    } catch (error) {
      if (error.response) {
        dispatch({
          type: AuthActionType.REGISTER_FAIL,
          payload: error.response.data.message,
        });
        setErrorHandler({
          hasError: true,
          message: error.response.data.message,
        });
      }
    }
  };
};

const LoginAuthAction = (loginState, history, setErrorHandler) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(login, loginState);
      const { data } = res;

      NotifierInstance.setTitle("Login Success..").success(
        "Congratulations for successfull login."
      );

      dispatch({ type: AuthActionType.LOGIN_SUCCESS, payload: data });
      history.push("/brands");
    } catch (error) {
      if (error.response) {
        setErrorHandler({ hasError: true, message: error?.response?.data?.error_message });
        dispatch({
          type: AuthActionType.LOGIN_FAIL,
          payload: error.response.data.message,
        });
      } else {
        setErrorHandler({
          hasError: true,
          message: error.message ? error.message : "Something went wrong",
        });
      }
    }
  };
};

const LogOutAuthAction = (history) => {
  return async (dispatch) => {
    dispatch({
      type: AuthActionType.LOGOUT_SUCCESS,
    });
    history.push("/");
  };
};

const ForgetPasswordAuthAction = (formState, history, setErrorHandler) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${forgetPassword}`, formState);
      const { data } = res;

      NotifierInstance.setTitle("Forget Password").success("Reset Password Link Sent on mail.");
      history.push("/");
    } catch (error) {
      if (error.response) {
        setErrorHandler({ hasError: true, message: error?.response?.data?.error_message });
        dispatch({
          type: AuthActionType.FORGET_PASSWORD_FAIL,
          payload: error.response.data.message,
        });
      } else {
        setErrorHandler({
          hasError: true,
          message: error.message ? error.message : "Something went wrong",
        });
      }
    }
  };
};

const SetPasswordAuthAction = (loginState, history, setErrorHandler, token) => {
  return async (dispatch) => {
    try {
      const res = await axios.put(`${setPassword}/${token}`, loginState);
      const { data } = res;

      NotifierInstance.setTitle("Password Set Success..").success(
        "Congratulations your password set successfully."
      );

      dispatch({ type: AuthActionType.PASSWORD_SET_SUCCESS, payload: data });
      history.push("/");
    } catch (error) {
      if (error.response) {
        setErrorHandler({ hasError: true, message: error?.response?.data?.error_message });
        dispatch({
          type: AuthActionType.PASSWORD_SET_FAIL,
          payload: error.response.data.message,
        });
      } else {
        setErrorHandler({
          hasError: true,
          message: error.message ? error.message : "Something went wrong",
        });
      }
    }
  };
};

export {
  RegisterAuthAction,
  AuthActionType,
  LogOutAuthAction,
  LoginAuthAction,
  SetPasswordAuthAction,
  ForgetPasswordAuthAction,
};
