import { brand } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";
import {AuthActionType} from "./auth.action"

export const ChannelActionTypes = {
  CREATE_BRAND: "CREATE_BRAND",
  DELETE_BRAND: "DELETE_BRAND",
  UPDATE_BRAND: "UPDATE_BRAND",
  GET_BRAND: "GET_BRAND",
  SET_BRAND: "SET_BRAND",
};

export const getChannel = () => {
  return (dispatch) =>
    axios.get(brand)
    .then((res) => {
      const { data } = res;
      dispatch({ type: ChannelActionTypes.GET_BRAND, payload: data });
      return res;
    })
    .catch((err) => {
      const _err = err?.response?.data?.error_message;
      NotifierInstance.setTitle("Channel Error..").error(_err);

      // on 403 remove auth token
      if (_err && err?.response?.status == 403) {
        dispatch({ type: AuthActionType.LOGOUT_SUCCESS});
      }

      throw err;
    });
};

export const createChannel = (payload) => {
  return (dispatch) =>
    axios
      .post(brand, payload)
      .then((res) => getChannel()(dispatch))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Channel Error..").error(_err);
        
        throw err;
      });
};

export const updateChannel = (payload) => {
  return (dispatch) =>
    axios
      .put(`${brand}/${payload._id}`, payload)
      .then((res) => {
        getChannel()(dispatch).then(() => {
          dispatch({ type: ChannelActionTypes.SET_BRAND, payload: payload._id });
        });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Channel Error..").error(_err);
        throw err;
      });
};

export const deleteChannel = (id) => {
  return (dispatch) =>
    axios
      .delete(`${brand}/${id}`)
      .then((res) => {
        getChannel()(dispatch);
        dispatch({ type: ChannelActionTypes.SET_BRAND, payload: null });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Channel Error..").error(_err);
        throw err;
      });
};

export const setChannel = (id) => (dispatch) => {
  dispatch({ type: ChannelActionTypes.SET_BRAND, payload: id });
};
