import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Table, Row, Col, Button } from "reactstrap";
import Swal from "sweetalert2";
import del from "assets/images/delete-24px.svg";
import CustomSpinner from "components/CustomSpinner";
import { getContent, deleteContent, deleteManyContent } from "../../redux/actions/showcase.action";

const CustomTable = ({ getContent, deleteContent, deleteManyContent, showcase, channel }) => {
  const [tableData, setTableData] = useState([]);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [errorInProcess, setErrorInProcess] = useState(null);
  const [isMultipleDel, setIsMultipleDel] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleDelete = (item) => (_) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteContent(item)
          .then((res) => {
            Swal.fire("Deleted!", "Your record has been deleted.", "success");
            fetchShowcaseList();
          })
          .catch((err) => {
            Swal.fire("Failure!", "Unable to delete.", "error");
          });
      }
    });
  };

  const handleCheckboxClick = (id) => {
    let arr = [...selectedItems];
    if (!arr.includes(id)) {
      arr.push(id);
    } else {
      arr.splice(arr.indexOf(id), 1);
    }
    setSelectedItems(arr);
  };

  const deleteMultipleList = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        setIsMultipleDel(false);
        deleteManyContent(selectedItems)
          .then((res) => {
            Swal.fire("Deleted!", "Your record has been deleted.", "success");
            setSelectedItems([]);
            fetchShowcaseList();
          })
          .catch((err) => {
            Swal.fire("Failure!", "Unable to delete.", "error");
          });
      }
    });
  };

  useEffect(() => {
    fetchShowcaseList();
  }, []);

  useEffect(() => {
    if (showcase.length > 0) {
      setTableData(showcase);
      setRequestInProcess(null);
      setErrorInProcess(null);
    }
  }, [showcase]);

  const fetchShowcaseList = () => {
    getContent()
      .then(({ data, status }) => {
        if (status == 200 && data && data.length > 0) {
          setTableData(data);
        } else setErrorInProcess("No showcase found");
      })
      .catch((err) => {
        setErrorInProcess("Unable to fetch showcase from DB");
      })
      .finally((_) => setRequestInProcess(false));
  };

  if (requestInProcess) return <CustomSpinner />;
  if (errorInProcess)
    return (
      <Fragment>
        <Row className="py-5">
          <Col sm={12} className="center">
            <h3 style={{ textAlign: "center" }}>{errorInProcess}</h3>
          </Col>
        </Row>
      </Fragment>
    );

  const styles = {
    maxHeight: "400px",
    overflow: "auto",
    height: "100%",
  };

  if (tableData && tableData.length > 0)
    return (
      <Fragment>
        {tableData && tableData.length > 1 && !isMultipleDel ? (
          <Button
            style={{ marginBottom: "20px", outline: "none" }}
            onClick={() => {
              setIsMultipleDel(true);
            }}
          >
            Delete Multiple
          </Button>
        ) : isMultipleDel ? (
          <Button
            style={{ marginBottom: "20px", outline: "none" }}
            onClick={() => {
              deleteMultipleList();
            }}
          >
            Delete
          </Button>
        ) : null}
        <div className="table--container" style={styles}>
          <Table hover>
            <thead>
              <tr>
                {["Sr.No", "ID", "Name", "Url", "Action", " "].map((item, index) => (
                  <Fragment key={`${item}${index}`}>
                    <th className="center">{item}</th>
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) =>
                item && typeof item === "object" && Object.keys(item).length > 0 ? (
                  <Fragment key={index}>
                    <tr className="center">
                      <td>{index + 1}</td>
                      <td>{item.showcase_id || "-"}</td>
                      <td>{item.showcase_name || "-"}</td>
                      <td>{item.showcase_url || "-"}</td>
                      {!isMultipleDel ? (
                        <td onClick={handleDelete(item)} className="pointer">
                          <img src={del} alt="delete" />
                        </td>
                      ) : null}
                      {isMultipleDel ? (
                        <td>
                          <input type="checkbox" onClick={() => handleCheckboxClick(item._id)} />
                        </td>
                      ) : null}
                    </tr>
                  </Fragment>
                ) : (
                  <Fragment>
                    <h2>No Data</h2>
                  </Fragment>
                )
              )}
            </tbody>
          </Table>
        </div>
      </Fragment>
    );
  else
    return (
      <Row>
        <Col sm={12} className="center py-5">
          <h3>No Records</h3>
        </Col>
      </Row>
    );
};

function mapStateToProps(state) {
  const { showcase = [], channel = [] } = state;
  return { showcase, channel };
}
export default connect(mapStateToProps, { getContent, deleteContent, deleteManyContent })(
  CustomTable
);
