import { useEffect, useState } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
import routes from "routes";
import Protectedroute from "layouts/utils/Protectedroute";
// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";
import Notifier from "./Notifier";
import { connect } from "react-redux";
import { getChannel } from "redux/actions/channel.action";
import { setChannel } from "redux/actions/channel.action";

const App = (props) => {
  const [controller, dispatch] = useSoftUIController();
  const { direction, layout, openConfigurator } = controller;
  const { pathname } = useLocation();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("channel_id")) {
      props.getChannel()
      .then(() => {
        const channel_id = localStorage.getItem("channel_id");
        if (channel_id) {
          props.setChannel(channel_id);
        }
        setReady(true);
      })
      .catch(err=> {
        setReady(true);
      })
    } else {
      setReady(true);
    }
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Protectedroute
            preAuth={route.preAuth || false}
            postAuth={!!!route.preAuth}
            exact
            path={route.route}
            component={route.component}
            key={route.key}
            redirectTo={route.redirectTo || "/"}
            auth={props.auth}
          />
        );
      }

      return null;
    });

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* {layout === "dashboard" && <>{ready && <Sidenav routes={routes} />}</>} */}
          <Switch>
            {ready && getRoutes(routes)}
          </Switch>
        </ThemeProvider>
      </StyledEngineProvider>

      <Notifier />
    </>
  );
};

export default connect(
  (state) => ({ auth: state.authState }),
  (dispatch) => {
    return {
      getChannel: () => dispatch(getChannel()),
      setChannel: (payload) => dispatch(setChannel(payload)),
    };
  }
)(App);
