import axios from "axios";
import { AuthActionType } from "redux/actions/auth.action";
import { ChannelActionTypes } from "redux/actions/channel.action";

const channelState = {
  current: {},
  list: [],
};

const channelReducer = (state = channelState, action) => {
  switch (action.type) {
    case ChannelActionTypes.GET_BRAND: {
      return { ...state, list: [...action.payload] };
    }
    case ChannelActionTypes.SET_BRAND: {
      localStorage.setItem("channel_id", action.payload);

      return {
        ...state,
        current: state.list.find((channel) => channel._id === action.payload) || {},
      };
    }
    case AuthActionType.LOGIN_SUCCESS: {
      return channelState;
    }
    default:
      return state;
  }
};

export default channelReducer;
