class Auth {
    constructor() {
      this.ls = window.localStorage;
      this.AUTH_TOKEN_NAME = "auth-token";
    }
  
    get isAuth() {
      return this.ls.getItem(this.AUTH_TOKEN_NAME) ? true : false;
    }
  
    set token(authTkn) {
      this.ls.setItem(this.AUTH_TOKEN_NAME, authTkn);
    }
  }
  
  export default new Auth();
  