import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SuiTypography from "components/SuiTypography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SuiBox from "components/SuiBox";

import "./select.module.scss";

function SuiSelect(props) {
  const {
    options,
    onChange,
    label,
    value,
    name,
    multi = false,
    children,
    renderVal = (x) => x,
    placeholder,
    customBody,
  } = props;

  const handleSelectChange = (obj) => {
    const { name: _name, value: _val } = obj.target || {};

    onChange({
      [_name || ""]: _val,
    });
  };

  const [val, setVal] = useState(multi ? [] : "");

  useEffect(() => {
    setVal(multi ? value || [] : value || "");
  }, [value]);

  const template = (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={val}
      multiple={multi}
      name={name}
      onChange={handleSelectChange}
      sx={{ "&>#demo-simple-select": { flex: 1 } }}
      renderValue={renderVal}
      placeholder={placeholder}
    >
      {!multi && !customBody
        ? (options || []).map(({ value, label: _label }) => (
            <MenuItem value={value}>{_label}</MenuItem>
          ))
        : children({ val })}
    </Select>
  );

  const Elem = label ? SuiBox : React.Fragment;
  return (
    <Elem flex={1}>
      {label && (
        <SuiTypography variant="overline" textColor="white">
          {label}
        </SuiTypography>
      )}
      {template}
    </Elem>
  );
}

SuiSelect.propTypes = {};

export default SuiSelect;
