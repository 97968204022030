export const base_url = "https://nop5hvu3uc.execute-api.ap-south-1.amazonaws.com";
// export const base_url = "http://bbae-122-168-63-130.ngrok.io";

// export const base_url = "http://127.0.0.1:80";

export const login = "/auth/login";

export const setPassword = "/user/set-password";

export const forgetPassword = "/user/forget-password";

export const dashboard = "/dashboard";

export const content = "/videos";

export const liveContent = "/live-content";

export const scheduledContent = "/schedule";

export const showcaseContent = "/showcase";

export const brand = "/brand";

export const uploadContentURL = "/upload-content";

export const contentUrl = "/content";

export const playlist = "/playlist";

export const user = "/user";

export const category = "/category";
