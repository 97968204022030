/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";

// Custom styles for the DefaultProjectCard
import styles from "examples/Cards/ProjectCards/DefaultProjectCard/styles";
import { Avatar, AvatarGroup, Icon } from "@mui/material";

function DefaultProjectCard({
  image,
  label,
  title,
  description,
  action,
  authors,
  channelData,
  onEditClick,
  onDeleteClick,
  userlist = [],
  currentUser = {},
  brandid,
}) {
  const classes = styles();

  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <SuiAvatar src={media} alt={name} size="xs" customclass={classes.projectCard_avatar} />
    </Tooltip>
  ));

  const renderUsers = () => {
    const { users = [] } = channelData || {};
    return users.reduce((acc, current) => {
      const _user = userlist.find((usr) => usr._id === current);
      if (_user) {
        return [
          ...acc,
          <SuiAvatar
            key={_user._id}
            sx={{ width: 60, height: 60 }}
            alt={`${_user.firstname}${" " + _user.lastname}`}
            src={_user.avatar}
          />,
        ];
      }
      return acc;
    }, []);
    // <Avatar sx={{ width: 60, height: 60 }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
  };

  return (
    <Card className={classes.projectCard}>
      <SuiBox customclass={classes.projectCard_imageContainer}>
        <CardMedia
          src={image}
          component="img"
          title={title}
          className={classes.projectCard_image}
          sx={{ minHeight: "12rem" }}
        />
      </SuiBox>
      <SuiBox pt={3} px={0.5}>
        <SuiBox display="flex">
          <SuiBox flex={1}>
            <SuiBox mb={1}>
              <SuiTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                textGradient
              >
                {label}
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1}>
              {action.type === "internal" ? (
                <SuiTypography
                  component={Link}
                  to={action.route}
                  variant="h5"
                  textTransform="capitalize"
                >
                  {title}
                </SuiTypography>
              ) : (
                <SuiTypography
                  component="a"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  textTransform="capitalize"
                >
                  {title}
                </SuiTypography>
              )}
            </SuiBox>
          </SuiBox>
          <SuiBox>
            <AvatarGroup max={3} spacing={10}>
              {renderUsers()}
            </AvatarGroup>
          </SuiBox>
        </SuiBox>
        <SuiBox mb={3} lineHeight={0}>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            {description}
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={3} lineHeight={0}>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Brand ID: {brandid}
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex" justifyContent="space-between" alignItems="center">
          {action.type === "internal" ? (
            <SuiButton
              component={Link}
              to={action.route}
              variant="outlined"
              size="small"
              buttonColor={action.color}
            >
              {action.label}
            </SuiButton>
          ) : (
            <SuiButton
              variant="outlined"
              size="small"
              buttonColor={action.color}
              onClick={action.onClick}
              sx={{ flex: 1 }}
              style={{ marginRight: "1rem" }}
            >
              {action.label}
            </SuiButton>
          )}
          {currentUser.role === "admin" && (
            <SuiBox display="flex">
              <SuiButton size="small" onClick={onEditClick} style={{ marginRight: "1rem" }}>
                <Icon>edit</Icon>
              </SuiButton>
              <SuiButton size="small" onClick={onDeleteClick}>
                <Icon>delete</Icon>
              </SuiButton>
            </SuiBox>
          )}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default DefaultProjectCard;
