import CheckIcon from "@mui/icons-material/Check";
import { Card, Grid, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import Logo from "../../assets/images/logo.jpg";
import FileUpload from "components/FileUpload";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiToggle from "components/SuiToggle";
import SuiLogout from "components/SuiLogout";
import SuiModal from "components/SuiModal";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import { useSoftUIController } from "context";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import { useFormState } from "helpers/hooks/form.hook";
import styles from "./style";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import {
  createChannel,
  deleteChannel,
  getChannel,
  setChannel,
  updateChannel,
} from "../../redux/actions/channel.action";
import { getUser } from "../../redux/actions/users.action";

function Channels(props) {
  const platformList = {
    apple_tv: "Apple TV",
    android_tv: "Android TV",
    android_mobile: "Android Mobile",
    fire_tv: "Fire TV",
    ios_mobile: "iOS Mobile",
    roku: "Roku",
    web: "Web",
  };

  const { user } = props;
  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    Promise.all([props.get(), props.getUsers()]);
  }, []);

  useEffect(() => {
    dispatch({ type: "LAYOUT", value: "page" });
  }, [pathname]);

  const classes = styles();

  const [formState, handleInputChange, resetFormState] = useFormState({
    platform: {
      android_tv: false,
      android_mobile: false,
      fire_tv: false,
      ios_mobile: false,
      apple_tv: false,
      web: false,
      roku: false,
    },
    showcase: false,
  });
  const [selectedChannel, setSelectedChannel] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClose = (func) => () => {
    func(false);
    setSelectedChannel({});
    resetFormState({
      platform: {
        android_tv: false,
        android_mobile: false,
        fire_tv: false,
        ios_mobile: false,
        apple_tv: false,
        web: false,
        roku: false,
      },
      showcase: false,
    });
  };
  const handleShow = (func) => () => {
    func(true);
  };

  const handleDelete = () => {
    props.delete(selectedChannel._id).then(() => {
      handleClose(setShowDeleteModal)();
      props.get();
    });
  };

  const platformSelection = (data) => {
    handleInputChange({ platform: { ...formState.platform, ...data } });
  };

  const showcaseSelection = (data) => {
    handleInputChange(data);
  };

  const renderChannelCard = () => {
    if (props?.channel?.length < 1) {
      return (
        <Grid
          item
          xs={12}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          No Brand Found !!
        </Grid>
      );
    }
    return (props?.channel || []).map((channel, index) => (
      <Grid item xs={12} md={6} xl={3} mb={3} key={channel._id}>
        <DefaultProjectCard
          label=""
          currentUser={user}
          userlist={props.users}
          channelData={channel}
          key={channel._id}
          image={channel.brand_logo || Logo}
          title={channel.name}
          brandid={channel._id}
          description={channel?.description || "-"}
          // onDeleteClick={() => {
          //   setSelectedChannel(channel);
          //   handleShow(setShowModal)();
          // }}
          onEditClick={() => {
            setSelectedChannel(channel);
            handleInputChange(channel);
            handleShow(setShowModal)();
          }}
          onDeleteClick={() => {
            setSelectedChannel(channel);
            handleInputChange(channel);
            handleShow(setShowDeleteModal)();
          }}
          action={{
            color: "info",
            label: "view ",
            onClick: () => {
              props.set(channel._id);
              history.push("/schedule");
            },
            route: "",
          }}
        />
      </Grid>
    ));
  };

  const renderSwitch = (item) => {
    return (
      <SuiToggle
        key={item}
        onChange={platformSelection}
        label={platformList[item]}
        name={item}
        value={formState?.platform[item]}
      ></SuiToggle>
    );
  };

  return (
    <>
      <SuiBox px={4} py={4}>
        <div className="channels-list">
          <SuiBox position="relative" pb={3}>
            {/* <SuiBox customclass={classes.profileHeader_background} /> */}
            <Card className={classes.profileHeader_profile}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <SuiAvatar
                    src={Logo}
                    alt="LitTV Network"
                    // variant="rounded"
                    size="lg"
                    // customclass="shadow-sm"
                  />
                </Grid>
                <Grid item>
                  <SuiBox height="100%" mt={0.5} lineHeight={1}>
                    <SuiTypography variant="h5" fontWeight="medium">
                      {user?.firstname || ""} {user?.lastname || ""}
                    </SuiTypography>
                    <SuiTypography variant="button" textColor="text" fontWeight="medium">
                      <span style={{ textTransform: "uppercase" }}>{user?.role}</span> /{" "}
                      <span>{user?.email}</span>
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} md={2} lg={2} className="m-auto " display={"flex"}>
                  <Link
                    to={"/brands"}
                    key={"brands"}
                    // className={classes.sidenav_navlink}
                  >
                    <SidenavCollapse
                      name={"Brands"}
                      icon={"splitscreen"}
                      active={true}
                      // noCollapse={noCollapse}
                    />
                  </Link>
                  {user.role === "admin" && (
                    <Link
                      to={"/users"}
                      key={"users"}
                      // className={classes.sidenav_navlink}
                    >
                      <SidenavCollapse
                        // name={"Admins"}
                        name={"Users"}
                        icon={"group"}
                        active={true}
                        // noCollapse={noCollapse}
                      />
                    </Link>
                  )}
                </Grid>
                <Grid item xs={12} md={2} lg={2} className="ml-auto">
                  <SuiLogout />
                </Grid>
              </Grid>
            </Card>
          </SuiBox>

          <SuiBox mb={3}>
            <Card>
              {/* <SuiBox pt={2} px={2}>
                <SuiBox mb={0.5}>
                  <SuiTypography variant="h6" fontWeight="medium">
                    Brands
                  </SuiTypography>
                </SuiBox>
              </SuiBox> */}
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiTypography variant="h6">Brands</SuiTypography>
                {user.role === "admin" && (
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={handleShow(setShowModal)}
                  >
                    Add Brand
                  </SuiButton>
                )}
              </SuiBox>
              <SuiBox p={2}>
                <Grid container spacing={3}>
                  {/* {user.role === "admin" && (
                    <Grid item xs={12} md={6} xl={3}>
                      <PlaceholderCard
                        title={{ variant: "h5", text: "New Brand" }}
                        outlined
                        onClick={handleShow(setShowModal)}
                      />
                    </Grid>
                  )} */}
                  {renderChannelCard()}
                </Grid>
              </SuiBox>
            </Card>
          </SuiBox>
        </div>
      </SuiBox>
      <SuiModal
        header={`${selectedChannel._id ? "Update" : "Create"} Brand`}
        open={showModal}
        dismissible={true}
        sx={{
          minWidth: "40rem !important",
          width: "40rem",
        }}
        closeModal={handleClose(setShowModal)}
      >
        <SuiBox
          as="form"
          display="flex"
          flexDirection="column"
          flex={1}
          onSubmit={(e) => {
            e.preventDefault();
            const funcToExec = selectedChannel._id ? props.update : props.create;
            funcToExec(formState).then(() => {
              handleClose(setShowModal)();
            });
          }}
        >
          <SuiBox mb={2}>
            <SuiInput
              label="Brand Name"
              name="name"
              value={formState.name || ""}
              placeholder="Enter brand name"
              onChange={handleInputChange}
            />
          </SuiBox>
          <SuiBox mb={2}>
            <SuiInput
              label="Brand Description"
              name="description"
              value={formState.description || ""}
              placeholder="Enter brand description"
              onChange={handleInputChange}
            />
          </SuiBox>

          <SuiBox mb={2}>
            <SuiTypography style={{ display: "block" }} variant="overline" textColor="white">
              Platforms
            </SuiTypography>
            {formState &&
              formState?.platform &&
              Object.keys(formState?.platform).map((item) => {
                return renderSwitch(item);
              })}
          </SuiBox>

          <SuiBox mb={2}>
            <SuiTypography style={{ display: "block" }} variant="overline" textColor="white">
              Showcase
            </SuiTypography>
            <SuiToggle
              key={formState.showcase}
              onChange={showcaseSelection}
              label="Is Showcase Available"
              name="showcase"
              value={formState.showcase}
            ></SuiToggle>
          </SuiBox>

          <SuiBox mb={2}>
            <SuiSelect
              label="Allowed Users"
              multi={true}
              name="users"
              placeholder="Select users"
              onChange={handleInputChange}
              value={formState.users || []}
              options={props.users}
              displayEmpty={true}
              renderVal={(selected) => `${selected.length} User${selected.length > 1 ? "s" : ""}`}
            >
              {({ val }) => {
                return props.users.reduce((acc, op, i) => {
                  if (op.role === "admin") return acc;
                  return [
                    ...acc,
                    <MenuItem key={op._id} value={op._id}>
                      <ListItemIcon sx={{ marginRight: "1rem" }}>
                        <SuiAvatar src={op.avatar} size="sm" variant="rounded" />
                      </ListItemIcon>
                      <ListItemText
                        fontSize="small"
                        secondary={`${op.firstname} ${op?.lastname || ""}`}
                      />

                      <ListItemIcon sx={{ mr: 1 }}>
                        <SuiBadge
                          variant="gradient"
                          badgeContent={op?.role}
                          color={op?.role === "admin" ? "error" : "success"}
                          size="extra-small"
                        />
                      </ListItemIcon>
                      <ListItemIcon>
                        {val.indexOf(op._id) === -1 ? null : (
                          <CheckIcon fontSize="medium" color="success"></CheckIcon>
                        )}
                      </ListItemIcon>
                    </MenuItem>,
                  ];
                }, []);

                // return <SuiBox maxHeight="20rem">{x}</SuiBox>;
              }}
            </SuiSelect>
          </SuiBox>

          <SuiBox mb={2}>
            <FileUpload
              name="brand_logo"
              label="Brand Logo"
              url={formState.brand_logo}
              onChange={handleInputChange}
            ></FileUpload>
          </SuiBox>

          <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
            <SuiBox>
              <SuiButton type="submit" buttonColor="black">
                {selectedChannel._id ? "Update" : "Create"}
              </SuiButton>
            </SuiBox>
            <SuiBox ml={1}>
              <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShowModal)}>
                Cancel
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiModal>
      <SuiModal
        header="Delete Content"
        open={showDeleteModal}
        closeModal={handleClose(setShowDeleteModal)}
      >
        <SuiBox display="flex" flexDirection="column" alignItems="center" flex={1}>
          <SuiBox display="flex" alignItems="center">
            <SuiTypography variant="body2" textColor="light">
              Are you sure, you want to delete <strong>{selectedChannel?.name || "-"}</strong> ?
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
            <SuiBox>
              <SuiButton type="submit" buttonColor="black" onClick={handleDelete}>
                Yes
              </SuiButton>
            </SuiBox>
            <SuiBox ml={1}>
              <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShowDeleteModal)}>
                No
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiModal>
    </>
  );
}

Channels.propTypes = {};

const mapStateToProps = (state) => {
  return {
    user: state?.authState?.user || {},
    channel: state?.channel?.list || [],
    users: state?.users?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (payload) => dispatch(createChannel(payload)),
    get: () => dispatch(getChannel()),
    getUsers: () => dispatch(getUser()),
    delete: (id) => dispatch(deleteChannel(id)),
    update: (payload) => dispatch(updateChannel(payload)),
    set: (payload) => dispatch(setChannel(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Channels);
