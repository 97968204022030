import { NotificationManager } from "react-notifications";

class Notifier {
  timeOut = 5000;
  cb = undefined;
  title = null;

  setTimeout(ms) {
    this.timeOut = ms;
    return this;
  }
  setCallback(cb) {
    this.cb = cb;
    return this;
  }
  setTitle(title) {
    this.title = title;
    return this;
  }

  error(msg) {
    NotificationManager.error(msg, this.title, this.timeOut, this.cb);
  }
  success(msg) {
    NotificationManager.success(msg, this.title, this.timeOut, this.cb);
  }
  info(msg) {
    NotificationManager.info(msg, this.title, this.timeOut, this.cb);
  }
  warn(msg) {
    NotificationManager.warning(msg, this.title, this.timeOut, this.cb);
  }
}

export default Notifier;

export const NotifierInstance = new Notifier();
