import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { NotificationContainer } from "react-notifications";

import "react-notifications/lib/notifications.css";
// import "./notifier.scss";

function NotifierComponent(props) {
  return <NotificationContainer />;
}

const Notifier = (props) => {
  return ReactDOM.createPortal(
    <NotifierComponent {...props} />,
    window.document.getElementById("notifier-portal")
  );
};

Notifier.propTypes = {};

export default Notifier;
