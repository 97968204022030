import { useState, useEffect } from "react";

export const useFormState = (initialState) => {
  const [formState, setFormState] = useState(initialState);
  const handleInputChange = (valObj) => {
    setFormState({ ...formState, ...valObj });
  };
  const resetState = (data) => setFormState(data||{});
  
  return [formState, handleInputChange, resetState];
};
