import React from "react";
import { Redirect, Route } from "react-router-dom";
import Auth from "./auth";

const ProtectedRoute = (props) => {
  const { component, preAuth, postAuth, redirectTo, location, auth, ...restProps } = props;

  if ((preAuth && auth.isLoggedIn) || (postAuth && !auth.isLoggedIn))
    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: { from: location },
        }}
      />
    );
  return <Route component={component} {...restProps} />;
};

ProtectedRoute.defaultProps = {
  preAuth: false,
  postAuth: false,
};

export default React.memo(ProtectedRoute);
