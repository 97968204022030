import { GETADMIN_DATA, POSTADMIN_DATA } from "./types";
import { contentUrl } from "api.config";
import axios from "axios";

export const postAdminData = (data) => ({
  type: POSTADMIN_DATA,
  payload: data,
});

export const startPostAdminData = (id, data) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      var url = contentUrl;
      var method = "POST";
      if (id) {
        url += `/${id}`;
        method = "PUT";
      }
      axios({
        url,
        method,
        data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((resp) => {
          dispatch(postAdminData({ ...data, ...resp }));
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const getAdminData = (data) => ({
  type: GETADMIN_DATA,
  payload: data,
});

export const startGetAdminData = (id) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const url = `/content/${id}`;
      axios({
        url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((resp) => {
          dispatch(getAdminData({ ...resp }));
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
