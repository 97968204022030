import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  FormText,
  Label,
  Input,
  Button,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { startGetAdminData, startPostAdminData } from "../../redux/actions/content.action";
import LoadingOverlay from "react-loading-overlay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "assets/css/bootstrap.min.css";
import "./style.scss";
import axios from "axios";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      contentData: {},
      loader: false,
      bodycta1: "",
      bodycta2: "",
      bodycta3: "",
      bodycta4: "",
      footercta1: "",
      footercta2: "",
      footercta3: "",
    };
  }

  componentDidMount() {
    this.setState({
      loader: true,
    });
    const id = this.props.channel?.current?._id;
    this.props
      .startGetAdminData(id)
      .then((val) => {
        this.setState({
          loader: false,
          _id: this.props.content?.data[0]?._id,
          contentData: this.props.content?.data[0],
          bannerFileUrl: this.props.content?.data[0]?.banner?.url || "",
          bodyFile1Url: this.props.content?.data[0]?.bodySection?.[0]?.url || "",
          bodycta1: this.props.content?.data[0]?.bodySection?.[0]?.cta || "",
          bodyFile2Url: this.props.content?.data[0]?.bodySection?.[1]?.url || "",
          bodycta2: this.props.content?.data[0]?.bodySection?.[1]?.cta || "",
          bodyFile3Url: this.props.content?.data[0]?.bodySection?.[2]?.url || "",
          bodycta3: this.props.content?.data[0]?.bodySection?.[2]?.cta || "",
          bodyFile4Url: this.props.content?.data[0]?.bodySection?.[3]?.url || "",
          bodycta4: this.props.content?.data[0]?.bodySection?.[3]?.cta || "",
          footerFile1Url: this.props.content?.data[0]?.footerSection?.[0]?.url || "",
          footercta1: this.props.content?.data[0]?.footerSection?.[0]?.cta || "",
          footerFile2Url: this.props.content?.data[0]?.footerSection?.[1]?.url || "",
          footercta2: this.props.content?.data[0]?.footerSection?.[1]?.cta || "",
          footerFile3Url: this.props.content?.data[0]?.footerSection?.[2]?.url || "",
          footercta3: this.props.content?.data[0]?.footerSection?.[2]?.cta || "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {}

  handleFileUploadChange = async (e) => {
    let fileName = e.target.files[0].name;
    let response = await this.getUploadUrl(fileName);
    let fileUrl = response?.data?.url || "";
    this.setState(
      {
        [e.target.id]: {
          file: e.target.files[0],
          fileUrl: fileUrl,
          url: "",
        },
      },
      () => {
        console.log(this.state);
      }
    );
  };

  getUploadUrl = (filename) => {
    return axios.get(`file/generateUploadUrl/${filename}`);
  };

  uploadImage = (file, fileUrl, tempid, id) => {
    let token = axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Authorization"];
    axios({
      url: fileUrl,
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      data: file,
    })
      .then((resp) => {
        if (resp.status === 200) {
          let imageUrl = fileUrl.split("?")[0];
          this.setState({
            [tempid]: {
              file: "",
              fileUrl: "",
            },
            loader: false,
            [id]: imageUrl,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        axios.defaults.headers.common["Authorization"] = token;
      });
  };

  fileUpload = (e) => {
    let id = e.target.id || "";
    let tempid = id.replace("Url", "");
    if (this.state[tempid].fileUrl && this.state[tempid].file) {
      this.setState({
        loader: true,
      });
      this.uploadImage(this.state[tempid].file, this.state[tempid].fileUrl, tempid, id);
    } else {
      toast.error("Please Select Image First", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  handleCta = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = () => {
    const validate = [
      { image: this.state.bannerFileUrl, text: "Banner Image" },
      { image: this.state.bodyFile1Url, text: "Body Image one" },
      { image: this.state.bodyFile2Url, text: "Body Image second" },
      { image: this.state.bodyFile3Url, text: "Body Image Third" },
      { image: this.state.bodyFile4Url, text: "Body Image Four" },
      { image: this.state.footerFile1Url, text: "Footer Image one" },
      { image: this.state.footerFile2Url, text: "Footer Image two" },
      { image: this.state.footerFile3Url, text: "Footer image Third" },
    ];
    let errorText = "";
    // validate.map((val) => {
    //   if (!val.image) {
    //     errorText += val.text + " | ";
    //   }
    // });
    const data = {
      banner: {
        url: this.state.bannerFileUrl,
      },
      bodySection: [
        {
          url: this.state.bodyFile1Url,
          cta: this.state.bodycta1,
        },
        {
          url: this.state.bodyFile2Url,
          cta: this.state.bodycta2,
        },
        {
          url: this.state.bodyFile3Url,
          cta: this.state.bodycta3,
        },
        {
          url: this.state.bodyFile4Url,
          cta: this.state.bodycta4,
        },
      ],
      footerSection: [
        {
          url: this.state.footerFile1Url,
          cta: this.state.footercta1,
        },
        {
          url: this.state.footerFile2Url,
          cta: this.state.footercta2,
        },
        {
          url: this.state.footerFile3Url,
          cta: this.state.footercta3,
        },
      ],
    };

    if (errorText != "") {
      toast.error(`${errorText} is required`, {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      this.setState({
        loader: true,
      });
      let brand_id = this.props.channel?.current?._id;
      data.brand_id = brand_id;
      this.props
        .startPostAdminData(this.state._id, data)
        .then(() => {
          this.setState({
            loader: false,
          });
          toast.success("Content Saved !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch(() => {
          this.setState({
            loader: false,
          });
        });
    }
  };

  render() {
    const { history, auth, channel } = this.props;
    return auth?.isLoggedIn ? (
      <Fragment>
        <LoadingOverlay
          active={this.state.loader}
          spinner
          text="Loading your content..."
          styles={{ height: "100vh" }}
        >
          <div className="homepage-container">
            <Container>
              <Row>
                <Col sm={12}>
                  <Card className="wizard-card">
                    <CardTitle className="wizard-header">
                      <div className="header-wrapper">
                        <span
                          className="pointer back-btn"
                          title="brands"
                          onClick={() => {
                            history.push("/brands");
                          }}
                        >
                          &lt;&lt;&nbsp;Brands
                        </span>
                        <h5 className="title">{channel?.current?.name}</h5>
                      </div>
                    </CardTitle>
                    <CardBody>
                      <div className="custom-tabs mb-20">
                        <ul className="nav nav-tabs">
                          <li className="nav-item nav-item1">
                            <Link to="/schedule" className="nav-link">
                              Schedule
                            </Link>
                          </li>
                          <li className="nav-item nav-item1">
                            <Link to="/admin-ui" className="active nav-link">
                              Admin UI
                            </Link>
                          </li>
                          <li className="nav-item nav-item1">
                            <Link to="/showcase" className="nav-link">
                              Showcase
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="section">
                        <p>Banner Section</p>
                        <div className="priview-box">
                          <FormGroup>
                            <Label for="exampleFile">Upload Image</Label>
                            <Input
                              type="file"
                              name="file"
                              onChange={this.handleFileUploadChange}
                              accept="image/*"
                              id="bannerFile"
                            />
                            <FormText color="muted">
                              Upload Banner image of size 1000 x 500 (jpg or png)
                            </FormText>
                            <Button
                              color="primary btn-theme"
                              onClick={this.fileUpload}
                              id="bannerFileUrl"
                              size="sm"
                            >
                              Upload
                            </Button>
                          </FormGroup>
                          <div className="priview">
                            {this.state.bannerFileUrl && (
                              <img alt="banner" src={this.state.bannerFileUrl} />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="section">
                        <p>Body Section</p>
                        <div className="priview-box">
                          <FormGroup>
                            <Label for="exampleFile">Upload Image 1</Label>
                            <Input
                              type="file"
                              onChange={this.handleFileUploadChange}
                              name="file"
                              accept="image/*"
                              id="bodyFile1"
                            />
                            <FormText color="muted">
                              Upload image of size 1000 x 500 (jpg or png)
                            </FormText>
                            <Button
                              color="primary btn-theme"
                              onClick={this.fileUpload}
                              id="bodyFile1Url"
                              size="sm"
                            >
                              Upload
                            </Button>
                          </FormGroup>
                          <div className="priview">
                            {this.state.bodyFile1Url && (
                              <img alt="banner" src={this.state.bodyFile1Url} />
                            )}
                          </div>
                        </div>

                        <FormGroup>
                          <Label>CTA Link 1</Label>
                          <Input
                            type="text"
                            id="bodycta1"
                            onChange={this.handleCta}
                            value={this.state.bodycta1}
                            placeholder="Enter CTA Link"
                          />
                        </FormGroup>
                        <div className="priview-box">
                          <FormGroup>
                            <Label for="exampleFile">Upload Image 2</Label>
                            <Input
                              type="file"
                              onChange={this.handleFileUploadChange}
                              name="file"
                              accept="image/*"
                              id="bodyFile2"
                            />
                            <FormText color="muted">
                              Upload image of size 1000 x 500 (jpg or png)
                            </FormText>
                            <Button
                              color="primary btn-theme"
                              onClick={this.fileUpload}
                              id="bodyFile2Url"
                              size="sm"
                            >
                              Upload
                            </Button>
                          </FormGroup>
                          <div className="priview">
                            {this.state.bodyFile2Url && (
                              <img alt="banner" src={this.state.bodyFile2Url} />
                            )}
                          </div>
                        </div>
                        <FormGroup>
                          <Label>CTA Link 2</Label>
                          <Input
                            type="text"
                            id="bodycta2"
                            onChange={this.handleCta}
                            value={this.state.bodycta2}
                            placeholder="Enter CTA Link"
                          />
                        </FormGroup>
                        <div className="priview-box">
                          <FormGroup>
                            <Label for="exampleFile">Upload Image 3</Label>
                            <Input
                              type="file"
                              onChange={this.handleFileUploadChange}
                              name="file"
                              accept="image/*"
                              id="bodyFile3"
                            />
                            <FormText color="muted">
                              Upload image of size 1000 x 500 (jpg or png)
                            </FormText>
                            <Button
                              color="primary btn-theme"
                              onClick={this.fileUpload}
                              id="bodyFile3Url"
                              size="sm"
                            >
                              Upload
                            </Button>
                          </FormGroup>
                          <div className="priview">
                            {this.state.bodyFile3Url && (
                              <img alt="banner" src={this.state.bodyFile3Url} />
                            )}
                          </div>
                        </div>
                        <FormGroup>
                          <Label for="exampleFile">CTA Link 3</Label>
                          <Input
                            type="text"
                            id="bodycta3"
                            onChange={this.handleCta}
                            value={this.state.bodycta3}
                            placeholder="Enter CTA Link"
                          />
                        </FormGroup>
                        <div className="priview-box">
                          <FormGroup>
                            <Label for="exampleFile">Upload Image 4</Label>
                            <Input
                              type="file"
                              onChange={this.handleFileUploadChange}
                              name="file"
                              accept="image/*"
                              id="bodyFile4"
                            />
                            <FormText color="muted">
                              Upload image of size 1000 x 500 (jpg or png)
                            </FormText>
                            <Button
                              color="primary btn-theme"
                              onClick={this.fileUpload}
                              id="bodyFile4Url"
                              size="sm"
                            >
                              Upload
                            </Button>
                          </FormGroup>
                          <div className="priview">
                            {this.state.bodyFile4Url && (
                              <img alt="banner" src={this.state.bodyFile4Url} />
                            )}
                          </div>
                        </div>
                        <FormGroup>
                          <Label for="exampleFile">CTA Link 4</Label>
                          <Input
                            type="text"
                            onChange={this.handleCta}
                            value={this.state.bodycta4}
                            id="bodycta4"
                            placeholder="Enter CTA Link"
                          />
                        </FormGroup>
                      </div>
                      <div className="section">
                        <p>Footer Grid Section</p>
                        <div className="priview-box">
                          <FormGroup>
                            <Label>Upload Image 1</Label>
                            <Input
                              type="file"
                              onChange={this.handleFileUploadChange}
                              name="file"
                              accept="image/*"
                              id="footerFile1"
                            />
                            <FormText color="muted">
                              Upload image of size 400 x 400 (jpg or png)
                            </FormText>
                            <Button
                              color="primary btn-theme"
                              onClick={this.fileUpload}
                              id="footerFile1Url"
                              size="sm"
                            >
                              Upload
                            </Button>
                          </FormGroup>
                          <div className="priview">
                            {this.state.footerFile1Url && (
                              <img alt="banner" src={this.state.footerFile1Url} />
                            )}
                          </div>
                        </div>

                        <FormGroup>
                          <Label for="exampleFile">CTA Link 1</Label>
                          <Input
                            type="text"
                            id="footercta1"
                            onChange={this.handleCta}
                            value={this.state.footercta1}
                            placeholder="Enter CTA Link"
                          />
                        </FormGroup>
                        <div className="priview-box">
                          <FormGroup>
                            <Label>Upload Image 2</Label>
                            <Input
                              type="file"
                              onChange={this.handleFileUploadChange}
                              name="file"
                              accept="image/*"
                              id="footerFile2"
                            />
                            <FormText color="muted">
                              Upload image of size 400 x 400 (jpg or png)
                            </FormText>
                            <Button
                              color="primary btn-theme"
                              onClick={this.fileUpload}
                              id="footerFile2Url"
                              size="sm"
                            >
                              Upload
                            </Button>
                          </FormGroup>
                          <div className="priview">
                            {this.state.footerFile2Url && (
                              <img alt="banner" src={this.state.footerFile2Url} />
                            )}
                          </div>
                        </div>
                        <FormGroup>
                          <Label for="exampleFile">CTA Link 1</Label>
                          <Input
                            type="text"
                            onChange={this.handleCta}
                            value={this.state.footercta2}
                            id="footercta2"
                            placeholder="Enter CTA Link"
                          />
                        </FormGroup>
                        <div className="priview-box">
                          <FormGroup>
                            <Label>Upload Image 3</Label>
                            <Input
                              type="file"
                              onChange={this.handleFileUploadChange}
                              name="file"
                              accept="image/*"
                              id="footerFile3"
                            />
                            <FormText color="muted">
                              Upload image of size 400 x 400 (jpg or png)
                            </FormText>
                            <Button
                              color="primary btn-theme"
                              onClick={this.fileUpload}
                              id="footerFile3Url"
                              size="sm"
                            >
                              Upload
                            </Button>
                          </FormGroup>
                          <div className="priview">
                            {this.state.footerFile3Url && (
                              <img alt="banner" src={this.state.footerFile3Url} />
                            )}
                          </div>
                        </div>
                        <FormGroup>
                          <Label for="exampleFile">CTA Link 3</Label>
                          <Input
                            type="text"
                            onChange={this.handleCta}
                            value={this.state.footercta3}
                            id="footercta3"
                            placeholder="Enter CTA Link"
                          />
                        </FormGroup>
                      </div>
                      <div className="center1">
                        <Button color="primary btn-theme" onClick={this.handleSubmit} size="lg">
                          Submit
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </LoadingOverlay>
        <ToastContainer />
      </Fragment>
    ) : (
      <Fragment />
    );
  }
}
const AdminUi = withRouter(Admin);

function mapStateToProps(state) {
  const { authState: auth, content, channel } = state;
  return { auth, content, channel };
}
function mapDispatchToProps(dispatch) {
  return {
    startGetAdminData: (id) => dispatch(startGetAdminData(id)),
    startPostAdminData: (id, data) => dispatch(startPostAdminData(id, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUi);
