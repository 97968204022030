import React, { useState, Fragment } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import CustomSpinner from "components/CustomSpinner";
import { getContent, createContent } from "../../redux/actions/schedule.action";
import { store } from "redux/store";
import { connect } from "react-redux";

const Form1 = (props) => {
  const [formData, setFormData] = useState({
    title: null,
    video_url: null,
    start_time: null,
    end_time: null,
  });
  const [value, onDateChange] = useState([new Date(), new Date()]);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [errorInProcess, setErrorInProcess] = useState(null);
  const [formError, setFormError] = useState(null);

  const checkIfScheduleOverlap = (item) => {
    const all = store.getState().schedules || [];
    for (let i = 0; i < [...all].length; i++) {
      const scheduleItem = all[i];
      if (
        (item.endDate >= scheduleItem.startDate && item.endDate <= scheduleItem.endDate) ||
        (item.startDate >= scheduleItem.startDate && item.startDate <= scheduleItem.endDate)
      ) {
        setFormError(true);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (e) => {
    setRequestInProcess(true);
    e && e.preventDefault();
    const v1 = value[0].getTime();
    const v2 = value[1].getTime();
    const params = { ...formData, start_time: parseInt(v1), end_time: parseInt(v2) };

    if (params.start_time >= params.end_time) {
      setFormError("Start date cannot be greater or equals to end date");
      setRequestInProcess(false);
      return;
    }

    let isValid = checkIfScheduleOverlap(params);
    isValid
      ? props
          .createContent(params)
          .then(({ data, status }) => {
            if (status === 200 && data && data._id) {
              setErrorInProcess("schedule created successfully");
            }
          })
          .catch(({ response }) => {
            setFormError("Error: " + response?.data?.error_message);
          })
          .finally((_) => {
            setTimeout(() => setErrorInProcess(null), 3000);
            setRequestInProcess(false);
          })
      : setRequestInProcess(false);
  };

  const handleChange = (e) => {
    e && e.preventDefault();
    const { name, value } = (e && e.target) || {};
    if (name) {
      setFormData((state) => ({ ...state, [name]: value }));
      formError && setFormError(false);
    }
  };
  const inputProps = { onChange: handleChange };

  const changeHandler = (data) => {
    if (data && data[0] && data[1]) {
      onDateChange([new Date(data[0]), new Date(data[1])]);
    }
  };

  if (requestInProcess) {
    return <CustomSpinner />;
  }
  if (errorInProcess) {
    return (
      <Fragment>
        <Row className="py-5">
          <Col sm={12} className="center">
            <p>{errorInProcess}</p>
          </Col>
        </Row>
      </Fragment>
    );
  }
  return (
    <Form>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="title">Program Title</Label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="Enter Title"
              {...inputProps}
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="url">Video Url</Label>
            <Input
              type="text"
              name="video_url"
              id="url"
              placeholder="Enter Url"
              {...inputProps}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="scheduleDate" style={{ width: "100%" }}>
          Schedule Date
        </Label>
        <DateTimeRangePicker
          onChange={changeHandler}
          value={value}
          minDate={new Date()}
          required={true}
          format="dd-MM-y h:mm:ss a"
        />
      </FormGroup>
      <div className="center pt-3">
        <Button className="custom-btn" type="button" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
      {formError && (
        <Row className="pt-4">
          <Col sm={12}>
            <p className="center text-danger">
              * {typeof formError === "string" ? formError : window.settings.overlapError}
            </p>
          </Col>
        </Row>
      )}
      <Row>&nbsp;</Row>
    </Form>
  );
};

// export default Form1;
function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, { getContent, createContent })(Form1);
