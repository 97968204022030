import { showcaseContent } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";

export const ContentActionTypes = {
  CREATE_SHOWCASE_CONTENT: "CREATE_SHOWCASE_CONTENT",
  DELETE_SHOWCASE_CONTENT: "DELETE_SHOWCASE_CONTENT",
  UPDATE_SHOWCASE_CONTENT: "UPDATE_SHOWCASE_CONTENT",
  GET_SHOWCASE_CONTENT: "GET_SHOWCASE_CONTENT",
};

export const getContent = () => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id } = current;
    return axios
      .get(`${showcaseContent}/${_id}`)
      .then((res) => {
        const { data } = res;
        dispatch({ type: ContentActionTypes.GET_SHOWCASE_CONTENT, payload: data });
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Scheduled Content Error..").error(_err);
        throw err;
      });
  };
};

export const createContent = (payload) => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id } = current;
    return axios
      .post(showcaseContent, { ...payload, brand_id: _id })
      .then((res) => {
        getContent()(dispatch, getState);
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Showcase Content Error..").error(_err);
        throw err;
      });
  };
};

export const updateContent = (payload) => {
  return (dispatch, getState) =>
    axios
      .put(`${showcaseContent}/${payload._id}`, payload)
      .then((res) => getContent()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Showcase Content Error..").error(_err);
        throw err;
      });
};

export const deleteContent = (item) => {
  return (dispatch, getState) =>
    axios
      .delete(`${showcaseContent}/${item._id}`)
      .then((res) => res)
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Showcase Content Error..").error(_err);
        throw err;
      });
};

export const deleteManyContent = (ids) => {
  return (dispatch, getState) =>
    axios
      .delete(`${showcaseContent}/delete`, { data: { ids } })
      .then((res) => getContent()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Showcase Content Error..").error(_err);
        throw err;
      });
};
