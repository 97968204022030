import React, { useState } from "react";
import { useHistory } from "react-router";
import { NotifierInstance } from "../../../utils/notification/notify";
import { LoginAuthAction } from "../../../redux/actions/auth.action";

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";

// Images
import curved6 from "assets/images/login-background.png";
import backgroundImageLogin from "assets/images/login-background-main.svg";
import logo from "../../../assets/images/logo.jpg";

import { connect } from "react-redux";
import ErrorHandler from "../error/ErrorHandler";
import { padding } from "@mui/system";
import "./index.scss";

function SignIn(props) {
  const { login } = props;

  const [errorHandler, setErrorHandler] = useState({
    hasError: false,
    message: "",
  });

  const [loginState, setLoginState] = useState({});
  const history = useHistory();
  return (
    <BasicLayout
      background={backgroundImageLogin}
      // image={curved6}
      // className="login-background-image"
    >
      <Card>
        <img src={logo} className="login-logo"></img>
        <SuiBox p={2} mb={1} textAlign="center">
          Use your email and password to login into your account
        </SuiBox>

        <SuiBox pt={2} pb={3} px={3}>
          <SuiBox
            component="form"
            role="form"
            onSubmit={(event) => {
              event.preventDefault();
              login(loginState, history, setErrorHandler);
            }}
          >
            <SuiBox mb={1}>
              <ErrorHandler
                errorHandler={{ hasError: errorHandler.hasError, message: errorHandler.message }}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                type="email"
                placeholder="Email"
                name="email"
                onChange={(event) => {
                  setLoginState({ ...loginState, ...event });
                  setErrorHandler({});
                }}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                type="password"
                name="password"
                placeholder="Password"
                onChange={(event) => {
                  setLoginState({ ...loginState, ...event });
                  setErrorHandler({});
                }}
              />
            </SuiBox>

            <SuiBox mt={4} mb={1}>
              <SuiButton type="submit" variant="gradient" buttonColor="dark" fullWidth>
                Login
              </SuiButton>
            </SuiBox>
            <SuiBox mt={4} mb={1}>
              <div
                className="forget-password"
                onClick={(event) => {
                  event.preventDefault();
                  history.push("/auth/forgot-password");
                }}
              >
                Forgot Password ?
              </div>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (loginState, history, setErrorHandler) => {
      dispatch(LoginAuthAction(loginState, history, setErrorHandler));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
