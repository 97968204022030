import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import { ForgetPasswordAuthAction } from "../../../redux/actions/auth.action";
import { connect } from "react-redux";
import ErrorHandler from "../error/ErrorHandler";

function ForgotPassword(props) {
  const { forget } = props;
  const [errorHandler, setErrorHandler] = useState({
    hasError: false,
    message: "",
  });

  const [formState, setFormState] = useState({});
  const history = useHistory();

  useEffect(() => {}, [errorHandler]);

  return (
    <BasicLayout
      title="Enter Your Email"
      description="Link will send on the enter email to Reset the password"
      image={curved6}
    >
      <Card>
        <SuiBox p={1} mb={1} textAlign="center" />

        <SuiBox pt={2} pb={3} px={3}>
          <SuiBox
            component="form"
            role="form"
            onSubmit={(event) => {
              event.preventDefault();
              forget(formState, history, setErrorHandler);
            }}
          >
            <SuiBox mb={1}>
              <ErrorHandler
                errorHandler={{ hasError: errorHandler.hasError, message: errorHandler.message }}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                type="email"
                placeholder="Email"
                name="email"
                onChange={(event) => {
                  setFormState({ ...formState, ...event });
                }}
              />
            </SuiBox>

            <SuiBox mt={4} mb={1}>
              <SuiButton type="submit" variant="gradient" buttonColor="dark" fullWidth>
                Send Link
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forget: (formState, history, setErrorHandler) => {
      dispatch(ForgetPasswordAuthAction(formState, history, setErrorHandler));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
