import { AuthActionType } from "redux/actions/auth.action";
import { ContentActionTypes } from "redux/actions/showcase.action";

const showcaseContentState = {
  current: {},
  list: [],
};

const showcaseContentReducer = (state = showcaseContentState, action) => {
  switch (action.type) {
    case ContentActionTypes.GET_SHOWCASE_CONTENT: {
      return { list: [...action.payload] };
    }

    case AuthActionType.LOGIN_SUCCESS: {
      return showcaseContentState;
    }

    default:
      return state;
  }
};

export default showcaseContentReducer;
