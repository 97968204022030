/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Divider from "@mui/material/Divider";
// @mui material components
import Drawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
// Images
import Logo from "../../assets/images/logo.jpg";
// clsx is a utility for constructing className strings conditionally
import clsx from "clsx";
import SuiAvatar from "components/SuiAvatar";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard React context
import { useSoftUIController } from "context";
// Soft UI Dashboard React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
// Custom styles for the Sidenav
import styles from "examples/Sidenav/styles/sidenav";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
// react-router-dom components
import { NavLink, useLocation } from "react-router-dom";

function Sidenav({ routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const classes = styles({ miniSidenav, transparentSidenav });
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  const closeSizenav = () => dispatch({ type: "MINI_SIDENAV", value: true });

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      dispatch({
        type: "MINI_SIDENAV",
        value: false,
      });
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, route, href, inSideNav = true }) => {
      if (!inSideNav) return null;
      if (type === "collapse") {
        return href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            className={classes.sidenav_navlink}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key} className={classes.sidenav_navlink}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </NavLink>
        );
      }
      if (type === "title") {
        return (
          <SuiTypography
            key={key}
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            customclass={classes.sidenav_title}
          >
            {title}
          </SuiTypography>
        );
      }
      if (type === "divider") {
        return <Divider key={key} />;
      }
      return null;
    }
  );

  return (
    <Drawer
      {...rest}
      variant="permanent"
      customclass="test123"
      classes={{
        paper: clsx(classes.sidenav, {
          [classes.sidenav_open]: !miniSidenav,
          [classes.sidenav_close]: miniSidenav,
        }),
      }}
    >
      <SuiBox customclass={classes.sidenav_header} styles={{ background: "black" }}>
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={300}
          right={0}
          p={1.625}
          customclass="cursor-pointer"
          onClick={closeSizenav}
        >
          <SuiTypography variant="h6" textColor="secondary">
            <Icon className="font-bold">close</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex">
          <NavLink to="/brands">
            <SuiAvatar
              // component="img"
              src={rest?.channel?.channel_avatar || Logo}
              alt="Channel Logo"
              customclass={classes.sidenav_logo}
            />
          </NavLink>

          <SuiBox customclass={classes.sidenav_logoLabel}>
            <SuiBox>
              <NavLink to="/brands">
                <SuiTypography component="h6" variant="text" fontWeight="bold" textColor="white">
                  {rest?.channel?.name}
                </SuiTypography>
              </NavLink>
            </SuiBox>
            <SuiBox textAlign="left">
              <NavLink to="/brands">
                <SuiTypography
                  variant="body2"
                  fontWeight="light"
                  opacity={0.8}
                  textColor="white"
                  sx={{ fontSize: "12px" }}
                >
                  Lit TV Network
                </SuiTypography>
              </NavLink>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Divider />
      <List>{renderRoutes}</List>
    </Drawer>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state?.authState?.user || {},
    channel: state?.channel?.current || {},
  };
};
export default connect(mapStateToProps, {})(Sidenav);
