import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { connect } from "react-redux";

import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import BasicLayout from "layouts/auth/components/BasicLayout";
import curved6 from "assets/images/curved-images/curved14.jpg";
import { SetPasswordAuthAction } from "../../../redux/actions/auth.action";
import ErrorHandler from "../error/ErrorHandler";

function SetPassword(props) {
  const history = useHistory();
  const {token} = useParams();  
  const { resetPassword } = props;
  const [credentials, setCredentials] = useState({});
  const [errorHandler, setErrorHandler] = useState({
    hasError: false,
    message: "",
  });
  const changePassword=()=>{
   
   resetPassword(credentials, history, setErrorHandler,token);
  };

  return (
    <BasicLayout
      title="Set Password"
      description=""
      image={curved6}
    >
      <Card>
        <SuiBox p={1} mb={1} textAlign="center" />

        <SuiBox pt={2} pb={3} px={3}>
          <SuiBox 
          component="form" 
          role="form"
          onSubmit={(event) => {
              event.preventDefault();
              changePassword();
            }}
          >
            <SuiBox mb={2}>
              <SuiInput 
              type="password" 
              placeholder="Password" 
              // value={password}
              name="password"
              onChange={(event) => {
                setCredentials({ ...credentials, ...event });
              }}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput 
              type="password"
              placeholder="Confirm Password" 
              // value={confirmPassword}
              name="confirmpassword"
              onChange={(event) => {
                setCredentials({ ...credentials, ...event });
              }}
              />
            </SuiBox>
            <SuiBox mb={1}>
              <ErrorHandler
                errorHandler={{ hasError: errorHandler.hasError, message: errorHandler.message }}
              />
            </SuiBox>
          <SuiBox mt={4} mb={1}>
              <SuiButton type="submit" variant="gradient" buttonColor="dark" fullWidth>
                Set Password
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (credentials, history, setErrorHandler,token) => {
      dispatch(SetPasswordAuthAction(credentials, history, setErrorHandler,token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
