import React from "react";
import PropTypes from "prop-types";
import { Icon, IconButton } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import { connect } from "react-redux";
import { LogOutAuthAction } from "redux/actions/auth.action";
import { useHistory } from "react-router-dom";
import SuiButton from "components/SuiButton";

function Logout(props) {
  const { logout } = props;

  const history = useHistory();

  return (
    <IconButton
      color="inherit"
      aria-controls="notification-menu"
      aria-haspopup="true"
      variant="contained"
      onClick={() => {
        logout(history);
      }}
    >
      <SuiButton variant="gradient" buttonColor="dark">
                Logout
              </SuiButton>
      {/* <SuiTypography variant="button" fontWeight="medium" textColor={"dark"}>
        LOGOUT &nbsp;
      </SuiTypography> */}
      {/* <Icon>settings</Icon> */}
    </IconButton>
  );
}

Logout.propTypes = {};

export default connect(null, (dispatch) => {
  return {
    logout: (history) => dispatch(LogOutAuthAction(history)),
  };
})(Logout);
