import React, { useState, Fragment, useEffect } from "react";
import { Col, Row, Button, Form, FormGroup, FormText, Label, Input } from "reactstrap";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import CustomSpinner from "components/CustomSpinner";
import { getContent, createContent } from "../../redux/actions/showcase.action";
import { store } from "redux/store";
import { connect } from "react-redux";
import axios from "axios";

const ShowCaseForm = (props) => {
  const [formData, setFormData] = useState({
    showcase_name: null,
    showcase_url: null,
    showcase_id: null,
    showcase_image: null,
  });
  const [fileObj, setFileObj] = useState(null);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [errorInProcess, setErrorInProcess] = useState(null);
  const [formError, setFormError] = useState(null);

  const handleSubmit = (e) => {
    setRequestInProcess(true);
    e && e.preventDefault();
    const params = { ...formData };
    if (!params.showcase_name) {
      setFormError("Showcase name missing");
      setRequestInProcess(false);
      return;
    }

    if (!params.showcase_id) {
      setFormError("Showcase id missing");
      setRequestInProcess(false);
      return;
    }

    if (!params.showcase_url) {
      setFormError("Showcase url missing");
      setRequestInProcess(false);
      return;
    }

    props
      .createContent(params)
      .then(({ data, status }) => {
        if (status === 200 && data && data._id) {
          setErrorInProcess("showcase created successfully");
        }
      })
      .catch(({ response }) => {
        setFormError("Error: " + response?.data?.error_message);
      })
      .finally((_) => {
        setTimeout(() => setErrorInProcess(null), 3000);
        setRequestInProcess(false);
      });
  };

  const handleChange = (e) => {
    e && e.preventDefault();
    const { name, value } = (e && e.target) || {};
    if (name) {
      setFormData((state) => ({ ...state, [name]: value }));
      formError && setFormError(false);
    }
  };
  const inputProps = { onChange: handleChange };

  const handleFileUploadChange = async (e) => {
    let fileName = e.target.files[0].name;
    let response = await getUploadUrl(fileName);
    let fileUrl = response?.data?.url || "";
    setFileObj({
      file: e.target.files[0],
      fileUrl,
      url: "",
    });
  };

  useEffect(() => {
    if (fileObj?.file) {
      fileUpload();
    }
  }, [fileObj]);

  const getUploadUrl = (filename) => {
    return axios.get(`file/generateUploadUrl/${filename}`);
  };

  const uploadImage = (file, fileUrl) => {
    let token = axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Authorization"];
    axios({
      url: fileUrl,
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      data: file,
    })
      .then((resp) => {
        if (resp.status === 200) {
          let imageUrl = fileUrl.split("?")[0];
          setFormData({ ...formData, showcase_image: imageUrl });
          setFileObj({
            file: "",
            fileUrl: "",
            imageUrl,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        axios.defaults.headers.common["Authorization"] = token;
      });
  };

  const fileUpload = () => {
    if (fileObj?.fileUrl && fileObj?.file) {
      uploadImage(fileObj.file, fileObj.fileUrl);
    }
  };

  if (requestInProcess) {
    return <CustomSpinner />;
  }
  if (errorInProcess) {
    return (
      <Fragment>
        <Row className="py-5">
          <Col sm={12} className="center">
            <p>{errorInProcess}</p>
          </Col>
        </Row>
      </Fragment>
    );
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="title">Showcase Name</Label>
            <Input
              type="text"
              name="showcase_name"
              id="showcase_name"
              placeholder="Enter showcase name"
              onChange={handleChange}
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="url">Showcase ID</Label>
            <Input
              type="text"
              name="showcase_id"
              id="showcase_id"
              placeholder="Enter showcase id"
              onChange={handleChange}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="title">Showcase URL</Label>
            <Input
              type="text"
              name="showcase_url"
              id="showcase_url"
              placeholder="Enter showcase url"
              onChange={handleChange}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <div className="priview-box">
          <FormGroup>
            <Label for="exampleFile">Upload Image</Label>
            <Input
              type="file"
              name="file"
              onChange={handleFileUploadChange}
              accept="image/*"
              id="bannerFile"
            />
            <FormText color="muted">Upload showcase Image (jpg or png)</FormText>
          </FormGroup>
          <div className="priview">
            {fileObj?.imageUrl && <img alt="banner" src={fileObj.imageUrl} />}
          </div>
        </div>
      </Row>
      <div className="center pt-3">
        <Button className="custom-btn" type="submit">
          Submit
        </Button>
      </div>
      {formError && (
        <Row className="pt-4">
          <Col sm={12}>
            <p className="center text-danger">
              * {typeof formError === "string" ? formError : window.settings.overlapError}
            </p>
          </Col>
        </Row>
      )}
      <Row>&nbsp;</Row>
    </Form>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, { getContent, createContent })(ShowCaseForm);
