import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ShowcaseTabs from "../../components/ShowcaseTabs";
import "assets/css/bootstrap.min.css";
import "./styles.scss";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

function Showcase({ history, showcase, channel }) {
  return (
    <Fragment>
      <div className="homepage-container">
        <Container>
          <Row>
            <Col sm={12}>
              <Card className="wizard-card">
                <CardTitle className="wizard-header">
                  <div className="header-wrapper">
                    <span
                      className="pointer back-btn"
                      title="brands"
                      onClick={() => {
                        history.push("/brands");
                      }}
                    >
                      &lt;&lt;&nbsp;Brands
                    </span>
                    <h5 className="title">{channel?.current?.name}</h5>
                  </div>
                </CardTitle>
                <CardBody>
                  <div className="custom-tabs mb-20">
                    <ul className="nav nav-tabs">
                      <li className="nav-item nav-item1">
                        <Link to="/schedule" className="nav-link">
                          Schedule
                        </Link>
                      </li>
                      <li className="nav-item nav-item1">
                        <Link to="/admin-ui" className="nav-link">
                          Admin UI
                        </Link>
                      </li>
                      <li className="nav-item nav-item1">
                        <Link to="/showcase" className="active nav-link">
                          Showcase
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ShowcaseTabs />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
const TEMP = withRouter(Showcase);

function mapStateToProps(state) {
  const { showcase = null, channel = nell } = state;
  return { showcase, channel };
}
export default connect(mapStateToProps, {})(TEMP);
