import { GETADMIN_DATA, POSTADMIN_DATA } from "../actions/types";
const data = {};

export default function (state = data, action) {
  switch (action.type) {
    case GETADMIN_DATA:
      return {...action.payload};
    case POSTADMIN_DATA:
      return {...action.payload, msg:"succesfully posted"};
    default:
      return state;
  }
}
