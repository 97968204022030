import React, { useState } from "react";
import PropTypes from "prop-types";

import SuiTypography from "components/SuiTypography";
import Switch from "@mui/material/Switch";
import './style.scss';

function SuiToggle(props) {
  const { label, value, onChange, name } = props;
  const handleToggleChange = (e) => {
    onChange({ [name]: e?.target?.checked || false });
  };
  
  return (
    <div style={{display:'inline-flex',flexDirection:'row',marginRight:'20px'}}>
      <Switch name={name} checked={value} onChange={handleToggleChange} />
      <SuiTypography style={{marginLeft:'10px'}} variant="overline" textColor="white">{label}</SuiTypography>
    </div>
  );
}

SuiToggle.propTypes = {};

export default SuiToggle;
