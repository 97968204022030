import Shop from "examples/Icons/Shop";
import SpaceShip from "examples/Icons/SpaceShip";
import ForgotPassword from "layouts/auth/forgot-password";
import ResetPassword from "layouts/auth/reset-password";
import SetPassword from "layouts/auth/set-password";
import LogIn from "layouts/auth/login";
import Home from "layouts/home";
import Brands from "layouts/brands";
import users from "layouts/users";
import AdminUi from "layouts/admin";
import Showcases from "layouts/showcase";

const routes = [
  {
    type: "collapse",
    name: "Schedule",
    key: "schedule",
    route: "/schedule",
    icon: "home",
    component: Home,
    noCollapse: true,
  },
  // Routes Hidden in SideNavbar
  {
    type: "collapse",
    name: "Login",
    key: "login",
    route: "/",
    icon: <SpaceShip size="12px" />,
    component: LogIn,
    noCollapse: true,
    inSideNav: false,
    preAuth: true,
    redirectTo: "/brands",
  },
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    route: "/auth/forgot-password",
    icon: <Shop size="12px" />,
    component: ForgotPassword,
    noCollapse: true,
    inSideNav: false,
    preAuth: true,
    redirectTo: "/brands",
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    route: "/auth/reset-password",
    icon: <Shop size="12px" />,
    component: ResetPassword,
    noCollapse: true,
    inSideNav: false,
    preAuth: true,
    redirectTo: "/brands",
  },
  {
    type: "collapse",
    name: "Set Password",
    key: "set-password",
    route: "/auth/set-password/:token",
    icon: <Shop size="12px" />,
    component: SetPassword,
    noCollapse: true,
    inSideNav: false,
    preAuth: true,
    redirectTo: "/brands",
  },
  {
    type: "collapse",
    name: "brands",
    key: "brands",
    route: "/brands",
    icon: "video_library",
    component: Brands,
    noCollapse: true,
    inSideNav: false,
  },
  {
    type: "collapse",
    name: "users",
    key: "users",
    route: "/users",
    icon: "video_library",
    component: users
  },
  {
    type: "collapse",
    name: "admin-ui",
    key: "admin-ui",
    route: "/admin-ui",
    icon: "video_library",
    component: AdminUi
  },
  {
    type: "collapse",
    name: "showcase",
    key: "showcase",
    route: "/showcase",
    icon: "video_library",
    component: Showcases
  }
];

export default routes;
